body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #002c3c !important;
    color: #002c3c;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
}

.h4, h4 {
    font-size: 1.5rem;
}

.h6, h6 {
    font-size: 1rem;
}

.text-white {
    color: #fff !important;
}

.p-a {
    padding: 1rem;
}


.form1 {
    max-width: 800px;
    margin: auto;
    background: #fff;
    padding: 23px;
    border-radius: 10px;
    width: 100%;
}

.logo {
    width: 100%;
    padding: 50px 0px;
    text-align: center;
    max-width: 800px;
    margin: auto;
    display: table;
}

.f1 {
    display: table-cell;
    vertical-align: middle;
}

.fleft {
    float: left;
}

.logo img {
    max-width: 100%;
    margin: auto;
    display: table-cell;
    vertical-align: middle;
}

#root, .h-100, .ant-spin-blur, .ant-spin-container, .ant-spin-nested-loading {
    height: 100%;
    width: 100%;
}

.clear {
    display: block;
    overflow: hidden;
}

.m-t {
    margin-top: 1rem;
}

.box, .box-color {
    background-color: #fff;
    position: relative;
    color: rgba(0, 0, 0, .87) !important;
}

.b-b {
    border-bottom: 1px solid rgba(120, 130, 140, .13);
}

.box-header {
    position: relative;
    padding: 1rem;
}

.box-body {
    padding: 1rem;
}

.box-body label {
    color: #000;
    line-height: normal;
}

.box-body .ant-form-item {
    margin-bottom: 0px;
}

.ax {
    letter-spacing: 0.5em;
    font-weight: bold;
}

.text-center {
    text-align: center !important;
}

.dark {
    color: rgba(225, 235, 245, .87);
    background-color: #263449;
}

.p-a {
    padding: 1rem;
}

.ant-form-item-with-help {
    margin-bottom: 20px;
}


.ant-checkbox-group-item {
    width: 48%;
    font-size: 13px !important;
}

@media only screen and (max-width: 696px) {
    .ant-checkbox-group-item {
        width: 100%;
        font-size: 14px !important;
    }
}

.mr-auto {
    margin: auto;
    display: table;
}


.headerBar {
    max-width: 700px;
    margin: auto;
    background: #fff;
    padding: 23px;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.button1 {
    border-radius: 5px !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16) !important;
    color: #fff !important;
    display: inline-block !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    height: 33px !important;
    padding: 5px 10px !important;
    position: relative !important;
    text-decoration: none !important;
    background-color: #c72f60;
}


.button1 .anticon {
    vertical-align: 0 !important;
    margin-right: 3px;
}

.button1.go-shop {
    margin-left: auto;
    margin-right: auto;
    display: table !important;
    width: auto;
    float: right;
}

.form-group {
    margin-bottom: 1rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.text-left {
    text-align: left !important;
}


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 576px) {
    .col-sm-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media (max-width: 576px) {
    h4 {
        font-size: 16px;
    }

    .logo {
        padding: 18px;
    }

    .logo .button1.go-shop {
        margin-top: 5px;
    }

    .box-body {
        padding-right: 0;
        padding-left: 0;
    }


}

@media (max-width: 375px) {
    .logo img {
        max-width: 150px;
    }
}

@media (max-width: 320px) {
    .logo img {
        max-width: 112px;
    }
}

.ol2 {
    margin-top: 20px;
    display: inline-block;
}

.ol li {
    color: rgba(0, 0, 0, 0.85);
}

.ant-checkbox-wrapper {
    line-height: 23px !important;
    font-size: 13px !important
}

.ant-form-item-children label {
    font-size: 13px
}

.ant-form-item-children strong {
    cursor: pointer;
}

.ant-form-item-control {
    line-height: inherit!important;
}
